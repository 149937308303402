import type { LocatorSearchItem } from '#types/brandify'

export const useBopisStore = (countryCode = useCountryCode()) => defineStore(`bopis_${countryCode}`, () => {
  const stores = ref<LocatorSearchItem[]>([])
  const selectedStore = ref<LocatorSearchItem>()
  const form = reactive({ postalCode: '', onlyStoresWithAvailability: false })

  return {
    form,
    stores,
    selectedStore,
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    paths: ['selectedStore', 'form']
  }
})()
